export const getTeamScore = (akka: number, pappi: number) => {
  return akka * -2 + pappi * -1;
};

export const getTotalScores = (game: Game) => {
  const team1score = getTeamScore(game.firstHalf.team1result.akka, game.firstHalf.team1result.pappi) +
    getTeamScore(game.secondHalf.team1result.akka, game.secondHalf.team1result.pappi) +
    getTeamScore(game.overtime.team1result.akka, game.overtime.team1result.pappi);

  const team2score = getTeamScore(game.firstHalf.team2result.akka, game.firstHalf.team2result.pappi) +
    getTeamScore(game.secondHalf.team2result.akka, game.secondHalf.team2result.pappi) +
    getTeamScore(game.overtime.team2result.akka, game.overtime.team2result.pappi);
  return {
    team1score,
    team2score,
  };
};
