import React, { useState } from "react";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { getTotalScores, getTeamScore } from "../../util/gameScores";
import GameHalf from "./GameHalf";
import Score from "./Score";

interface Props {
  activeGame: Game;
  submitScore: () => void;
  submitOt: (half: GameHalfResult) => void;
  cancelGame: () => void;
}

const Results: React.FunctionComponent<Props> = (props: Props) => {
  // const [modal, setModal] = useState(false);

  const { activeGame, submitScore, submitOt } = props;

  const regularGameReady =
    activeGame.firstHalf.ready && activeGame.secondHalf.ready;
  const { team1score, team2score } = getTotalScores(activeGame);
  const even = team1score === team2score;

  // Pelattavan sarjan saa irti pelin ensimmäisestä numerosta
  // 1 = yleinen
  // 3 = pro
  // 5 = firma
  const sarja = Number(activeGame.id[0]);

  // Kierroksen saa irti pelin id:n toisesta numerosta
  const kierros = Number(activeGame.id[1]);

  // Tarkistetaan onko peli jatkopeli
  const jatkopeli =
    // Kun kyseessä on yleinen sarja, jatokpelit alkaa kolmannella kierroksella jatkolohkon jälkeen
    (sarja == 1 && kierros > 2) ||
    // Kun kyseessä on pro, jatokpelit alkaa tokalla kierroksella
    (sarja == 3 && kierros > 1) ||
    // Kun kyseessä on pro, jatokpelit alkaa tokalla kierroksella
    (sarja == 5 && kierros > 1);

  // const toggleModal = () => {
  //   setModal(!modal);
  // };

  // const cancelGame = () => {
  //   setModal(!modal);
  //   props.cancelGame();
  // };

  return (
    <React.Fragment>
      {/* 2025:  kommentoi pois pelin keskeytys -modaali koska nappula onpi piilotettu */}
      {/* <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Pelin keskeytys</ModalHeader>
        <ModalBody>
          Oletko varma että haluat keskeyttää pelin? Toimintoa ei voi perua.
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Peruuta
          </Button>
          <Button color="danger" onClick={cancelGame}>
            Keskeytä peli
          </Button>
        </ModalFooter>
      </Modal> */}

      <h4>Tulos{!regularGameReady && " (peli on kesken)"}</h4>
      <p>Kokonaistulokset päivittyvät aina päädyn valmistuttua.</p>
      <Score game={activeGame} showId={false} />

      {regularGameReady && (
        <React.Fragment>
          {/* Jos kyseessä on jatkopeli joka on tasan, näytä lyhyen uusinnan näkymä */}
          {(jatkopeli && even) ? (
            <>
              <h4 className="text-danger">TASAPELI!</h4>
              <p className="text-danger">
                Kyseessä on pudotuspeli, pelatkaa lyhyt uusinta.
              </p>

              <h4>Lyhyt uusinta</h4>
              <p>
                Neljä kyykkätornia tasasin välein. Jokainen pelaaja heittää
                yhden kartun. Molemmat joukkueet pelaavat samaan päätyyn.
                <br />
                Jos uusinnasta tulee tasapeli, peluuta uusi lyhyt uusinta.
                Lähetä vain ratkaisevan uusinnan tulos.
              </p>
              <hr />
              <GameHalf
                activeGame={activeGame}
                half="overtime"
                onSubmit={(half) => {
                  // Calculate scores for both teams
                  const team1score = getTeamScore(half.team1result.akka, half.team1result.pappi)
                  const team2score = getTeamScore(half.team2result.akka, half.team2result.pappi)

                  // Only submit if overtime didn't end even
                  const overtimeEven = team1score === team2score;
                  if (!overtimeEven) {
                    submitOt(half);
                  }
                }}
                editHalf={() => { }}
              />
            </>
          ) : (
            // Näytä normaali lähetä-tulokset nappula jos kyseessä ei ole jatkopeli
            <Button outline block onClick={() => submitScore()}>
              Lähetä tulokset
            </Button>
          )}
        </React.Fragment>
      )}

      <hr />
      {/* 2025: poistetaan keskeytä peli-nappula koska se vaa aiheuttaapi hämmennystä */}
      {/* <Button outline block color="danger" onClick={toggleModal}>
        Keskeytä peli
      </Button> */}
    </React.Fragment>
  );
};

export default Results;
